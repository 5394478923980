import request from "@/utils/requset"

// 获取oss信息接口
export function getOssTempToken(data) {
  return request({
    url: "/upload/getOssTempToken",
    method: "post",
    data: data,
  });
}
// //提交接口
// export function returnWarehouseImg(data) {
//   return request({
//     url: "/oyraswk/returnWarehouseImg/addWarehouseImg",
//     method: "post",
//     data: data,
//   });
// }

//提交接口
export function returnWarehouseImg(data) {
  return request({
    url: "/oyraswk/salesOutboundImg/add",
    method: "post",
    data: data,
  });
}